.login__body-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.login__body-overlay {
    background: rgba(0, 0, 0, 0.666);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.login__body-overlay {
    
    [title="Användarnamn"] {
        color: white !important;
      }
      
    [title="Lösenord"] {
    color: white !important;
    }
}

.login__buttons {
    display: flex;
    justify-content: right;
}