.sidebar {
    display: none;
    position: fixed;
    transition: all 350ms;
    z-index: 9;
    width: 100%;
    height: 16rem;
    background: var(--infra-red-light);
    top: 3.5rem;
}

.sidebar__closed {
    transform: translateY(-28rem);
}

.sidebar__nav {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    justify-content: space-around;
    padding: 1.5rem 2rem;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.sidebar__nav a {
    z-index: 10;

    color: var(--infra-white);
    text-shadow: 1px 1px var(--infra-black);
    text-decoration: none;
    font-family: Archivo, sans-serif;
    font-size: 2.0rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    transition: all 250ms;
}

.sidebar__right {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-right: 2rem;
    gap: 1.3rem;
    align-items: end;
}

.sidebar__nav a:hover,
.sidebar__nav a:active {
    transform: translateY(-4px);
    text-shadow: 1px 4px var(--infra-black);
}


@media only screen and (max-width: 800px) {
    .sidebar {
        display: flex;
        align-items: center;
    }
}