.header__contents {
    position: relative;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    background: white;

    width: 1280px;
    margin: auto;
    padding: 0 1rem;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.header__content-logo {
    margin-top: auto;
    margin-bottom: auto;
}


.header__navigation {
    display: flex;
}

.header__navigation a {
    align-self: center;
    color: black;
    padding: 1.66rem 1rem;
    text-decoration: none;
    transition: all 250ms;
}

.header__navigation a:hover,
.header__navigation a:active,
.header__navigation a.active {
    background: var(--infra-nav-hover);
}

.header__buttons {
    display: flex;
}

.header__language-button {
    cursor: pointer;
    padding: 1rem;
    transition: all 250ms;
}

.header__language-button:hover,
.header__language-button:active {
    background: var(--infra-nav-hover);
}

.header__dropdown {
    padding-right: 4px;
}

.header__dropdown span {
    font-family: Archivo, sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.0625rem */
}

.header__sidebar-button {
    display: none;
}

@media only screen and (max-width: 1280px)  {
    .header__contents {
        width: 100%;
        margin: auto;
        position: fixed;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    }

    
}

@media only screen and (max-width: 1000px)  {
    .header__navigation a {
        padding: 1.66rem 0.5rem;
    }

    .header__contents img {
        width: 380px;
        padding-right: 0;
    }
}

@media only screen and (max-width: 800px)  {

    .header__navigation {
        display: none;
    }

    .header__contents {
        padding: 0;
        padding-top: 0.25rem;
    }

    .header__content-logo {
    }

    .header__content-logo img {
        width: 100%;
        padding-left: 0.25rem;
    }

    .header__sidebar-button {
        display: inline;
        padding: 1rem 0.75rem 0.70rem 0.75rem;
    }

    .header__language-button {
        padding: 1rem 0.75rem;
    }

    .header__sidebar-button:hover, 
    .header__sidebar-button:active {
        background: var(--infra-nav-hover);
    }
    
}
