.consent {
    z-index: 11;
    position: sticky;
    background: var(--infra-red-light);
    width: 100%;
    bottom: 0;
    padding-top: 0.25rem;
    padding-bottom: 1rem;

    box-shadow: 10px 10px 10px 12px rgba(0, 0, 0, 0.3);
}

.consent__closed {
    display: none;
}

.consent h1 {
    margin-bottom: 0;
    line-height: 100%;
    color: var(--infra-white); 
}

.consent p {
    color: var(--infra-white); 
    line-height: 150%;
}

.consent__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.consent_buttons-button {
    background: var(--infra-white);
    color: var(--infra-black);
    cursor: pointer;
    transition: 250ms all;
}