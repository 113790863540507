.page__spacing {
    padding: 2rem;
}

.page__spacing-double {
    padding: 3.5rem;
}

.page__button-container-horizontal {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.blocks__container {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media only screen and (max-width: 1280px)  {
    .page__contents {
        width: 100%;
        padding-top: 4.5rem;
    }

    .page__non-wide-component {
        padding: 0 0.5rem;
    }
}

@media only screen and (max-width: 800px) {
    .page__button-container-horizontal {
        gap: 1rem;
    }

    .page__contents {
        padding-top: 3.5rem;
    }

    .page__spacing {
        padding: 1.5rem;
    }
    
    .page__spacing-double {
        padding: 2.5rem;
    }
}