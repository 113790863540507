.privacy {

    
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1280px;
    margin: auto;
}

.privacy__cookie-button {
    background: var(--infra-black);
    color: var(--infra-white);
    cursor: pointer;
    transition: 250ms all;
    margin: auto;
}

@media only screen and (max-width: 1280px) {
    .privacy {
        padding: 1rem;
    }
}