.admin__main {
    height: 100vh;
}

.admin__main-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--infra-red);
    height: 6%;
    padding: 0 1.5rem;
}

.admin__main-topbar h1 {
    color: var(--infra-white);
}

.admin__main-topbar__buttons {
    display: flex;
    gap: 1rem;
}

.admin__main-body {
    background: var(--infra-background);
    height: 95%;
    
    display: flex;
    flex-direction: column;
    gap: 1rem;;
}

.admin__main-body-row {
    flex: 1;
    
    display: flex;
    gap: 1rem;
    flex-direction: row;
}

.admin__main-body-item {
    background-color: rgba(255, 255, 255, 0.666);
    margin: 1rem 0.5rem;
    padding: 1rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
}

.admin__main-button {
    width: 50%;
}


@media screen and (max-width: 650px) {
    .admin__main {
        height: 100%;
    }
    .admin__main-body {
        height: auto;
    }

    .admin__main-body-row {
        flex-direction: column;
    }

    .admin__main-topbar {
        padding: 0 0.5rem;
        height: 3rem;
    }
}