.footer {
    background: var(--infra-white);
    width: 1280px;
    margin: auto;
    padding-top: 1rem;
}

.footer__upper {
    display:flex;
    justify-content: space-between;
    margin: 1rem;
    flex-direction: row;
}

.footer__upper-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
}

.footer__upper-center {
    margin: auto;
}

.footer__lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
}


.footer hr {

}

.footer a {
    color: var(--InfraBlack);
}


.footer__navigation {
    display: flex;
    margin-left: 0.5rem;
    gap: 2rem;
}


.footer__navigation a {
    text-decoration: none;
    transition: all 250ms;
    font-size: 1.55rem;
}

.footer__navigation a:hover {
    transform: translateY(-3px);
    text-shadow: 0px 3px var(--infra-gray);
}

.footer__bottom-links {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    transition: all 250ms;
}

.footer__bottom-links a:hover,
.footer__bottom-links a:active {
    color: var(--infra-blue-dark);
}

.footer__uc {
    width: 15%;
    margin-top: 2rem;
}

@media only screen and (max-width: 1280px)  {
    .footer {
        width: 100%;
        margin: auto;
    }  
}

@media only screen and (max-width: 800px)  {
    .footer {
        padding-top: 1rem;
    }

    .footer__upper-left img {
        width: 100%;
    }

    .footer__navigation {
        justify-content: center;
    }

    .footer__navigation a {
        font-size: 1rem;
    }

    .footer__upper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    } 

    .footer__uc {
        width: auto;
        margin-top: 1rem;
    }

    .footer__lower {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } 

    .footer__bottom-links {
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
    }
}

