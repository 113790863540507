.button__all {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-align: center;

}

.button__box {
    min-width: 8rem;
    border: none;
    padding: 0.75rem 1rem; 
}

.button__box-black {
    background-color: black;
    color: white;
}

.button__box-white {
    background: white;
    color: black;
}

.button__box:hover, .button__box:active {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.button__outline {
    display: flex;
    min-width: 8rem;
    background: none;
    border-style: solid;
    border-width: 1px;
    padding: 0.75rem 1rem; 
}

.button__outline:hover, .button__outline:active {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.button__outline span {
    margin: auto;
}

.button__icon {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.75rem 1rem; 
}

.button__icon:hover, .button__outline:active {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.button__download {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.button__download span {
    margin-left: 0.25rem;
    margin-top: 4px;
}

@media only screen and (max-width: 800px) {
    .button__icon {
        padding: 0.5rem 0.75rem; 
    }

    .button__box {
        padding: 0.5rem 0.75rem; 
    }

    .button__outline {
        padding: 0.5rem 0.75rem; 
    }
}