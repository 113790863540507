


/* --- Landing block --------------------- */

.blocks__landing-image {
    background-color: gray;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    
}

.blocks__landing-overlay {
    background: rgba(0, 0, 0, 0.45);
    width: auto;
    height: 100%;
    
}

.blocks__landing-contents {
    box-sizing: border-box;
    height: 100%;
    padding: 0 12rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* --- TextAndImage block --------------------- */

.blocks__text-and-image {
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem;
    gap: 3rem;
}

.blocks__text-and-image_imageside-left {
    flex-direction: row-reverse;
}

.blocks__text-and-image_text {
    flex: 1;
}

.blocks__text-and-image_image {
    flex: 1;
}

.blocks__text-and-image_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* --- Contact block --------------------- */

.blocks__contact {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.blocks__contact-below {
    width: 100%;
    position: absolute;
    background-color: var(--infra-red-light);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 2rem;
}

.blocks__contact-below_content {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 50%;
}

.blocks__contact-below_content h2 {
    color: var(--infra-white)
}

.blocks__contact-below_content p {
    color: var(--infra-white)
}

.blocks__contact-above {
    position: relative;
    background-color: gray;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    
    width: 40%;
    height: 30rem;
    margin-left: 55%;
    bottom: 2.5rem;
}

.blocks__contact-above img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* --- Highlights block --------------------- */

.blocks__highlight {    
    padding: 2rem 2rem;
}

.blocks__highlight h2 {
    text-align: center;
}

.blocks__highlight h3 {
    text-align: center;
}

.blocks__highlight p {
    text-align: center;
}

.blocks__highlight-contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}

.blocks__highlight-contents_highlight {
    flex: 0 0 calc(33.333% - 2.667rem); /* Adjust based on your gap size */
    max-width: calc(33.333% - 2.667rem); /* Ensures items don't exceed this width */
}

.blocks__highlight-contents_highlight-image img {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    object-fit: cover;
}




/* --- Mobile versions --------------------- */
@media only screen and (max-width: 1080px) {
    .blocks__landing-contents {
        padding: 0 6rem;
    }

    .blocks__highlight-contents_highlight {
        flex: 0 0 calc(50% - 2.667rem); /* Adjust based on your gap size */
        max-width: calc(50% - 2.667rem); /* Ensures items don't exceed this width */
    }
}

@media only screen and (max-width: 800px) {
    .blocks__landing-contents {
        padding: 0 1rem;
    }

    .blocks__text-and-image {
        display: flex;
        flex-direction: column-reverse;
        padding: 1rem 1rem;
        padding-bottom: 2rem;
        gap: 0;
    }

    .blocks__text-and-image_imageside-left {
        flex-direction: column-reverse;
    }

    .blocks__highlight {
        padding: 1rem 1rem;
        padding-bottom: 2rem;
    }

    .blocks__highlight-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4rem;
        flex-wrap: wrap;
    }

    .blocks__highlight-contents_highlight {
        flex: 1;
        max-width: 100%;
    }

    .blocks__contact-above {
        display: none;
    }

    .blocks__contact-below_content {
        padding: 2rem;
        width: 100%;
    }

    .blocks__contact {
        margin-bottom: 26rem;
    }
}