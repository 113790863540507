.home__reviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home__reviews h3,
.home__reviews p {
    margin: 0 auto;
}

.home__reviews-image {
    margin: 2rem auto;
    width: 10rem;
    height: 10rem;
    background-color: gray;
}

@media only screen and (max-width: 1280px) {
    .home__reviews {
        padding: 0 1rem;
    }
}