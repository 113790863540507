.about__faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.faq-dropdown {
    margin: auto;
    width: 60%;
}

.faq-textbox {
    margin: auto;
    width: 60%;
}

@media only screen and (max-width: 800px) {

    .faq-dropdown {
        width: 92%;
    }

    .faq-textbox {
        width: 92%;
    }

}