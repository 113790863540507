.shop__landing-products {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
    padding: 0;
}

.shop__landing-products_product {
    margin-bottom: 0.5rem;
}

.shop__landing-products_product img {
    background: gainsboro;
    height: 12rem;
    width: 50%;
    margin: auto;
}

.shop__landing-products_product p {
    text-align: center;
    margin-top: 1rem;
}

@media only screen and (max-width: 800px) {
    .shop__landing-products_product img {
        height: 10rem;
        width: 75%;
    }
}