.contact__visit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.contact__visit iframe {
    border: none;
}

.contact__visit-info {
    background: var(--infra-red-light);
    width: 24rem;
    height: 24rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact__visit-info p {
    margin: 0;
    padding: 0;

    color: var(--infra-white);
    text-align: center;
}

.contact__employees {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    
    padding: 0 2rem;
}

.contact__employees h2 {
    text-align: center;
}

.contact__employees h3 {
    text-align: center;
}

.contact__employees p {
    text-align: center;
}

.contact__employees-contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}

.contact__employees-contents_employee {
    flex: 0 0 calc(33.333% - 2.667rem); /* Adjust based on your gap size */
    max-width: calc(33.333% - 2.667rem); /* Ensures items don't exceed this width */
}

.contact__employees-contents_employee p,
.contact__employees-contents_employee h3 {
    margin: 0.25rem 0;
}

.contact__employees-contents_employee-image {
    background: gray;
    height: 15rem;
    width: 100%;
    margin-bottom: 1rem;
}

.contact__employees-contents_employee-line {
    height: 2.0rem;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    gap: 0.5rem;
}

@media only screen and (max-width: 800px) {
    .contact__employees-contents {
        flex-direction: column;
        gap: 2rem;
    }

    .contact__employees-contents_employee {
        flex: 1;
        max-width: 100%;
    }

    .contact__visit {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }

    .contact__visit-info {
        width: 92%;
        height: 12rem;
    }

    .contact__visit iframe {
        width: 92%;
    }
}