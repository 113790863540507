.contact__form {
    position: relative;
    width: 100%;
    margin-top: 9rem;
    margin-bottom: 2rem;
    height: 38rem;
}

.contact__form-below {
    width: 100%;
    position: absolute;
    background-color: var(--infra-red-light);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 6rem;
    padding-top: 4rem;
}

.contact__form-below_content {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 45%;
}

.contact__form-below_content h2 {
    color: var(--infra-white)
}

.contact__form-below_content p {
    color: var(--infra-white)
}

.contact__form-above {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: var(--infra-form);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    
    width: 45%;
    margin-left: 50%;
    padding-top: 1.5rem;
    bottom: 7.5rem;
}

.contact__form-above h3 {
    margin: 0 0 0 2rem;
}

.contact__form-above_form {
    margin: 0.5rem 2rem;
}

.contact__form-above_form-lower {
    display: flex;
    justify-content: start;
}

.contact__form-above_form-lower p {
    margin-top: 0;
}

.contact__form-above_form-buttons {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    gap: 0.5rem;
}

.contact__form-above_form-buttons button {
    width: 100%;
}

.contact__form-above {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: var(--infra-form);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    
    width: 45%;
    margin-left: 50%;
    padding-top: 1.5rem;
    bottom: 7.5rem;
}

@media only screen and (max-width: 800px) {
    .contact__form {
        margin-top: 3rem;
        margin-bottom: 28rem;
    }

    .contact__form-below {
        position: relative;
        padding: 1rem 0;
        width: 92%;
        margin: auto;
    }

    .contact__form-below_content {
        width: 100%;
        padding: 0 1rem;
    }

    .contact__form-above {        
        width: 92%;
        top: 0;
        margin: 1rem auto;
    }
}