body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --infra-background: linear-gradient(153deg, rgba(224, 73, 29, 0.16) 0%, rgba(224, 73, 29, 0.51) 100%);
  --infra-red: #E0491D;
  --infra-red-light: #E76C49;
  --infra-blue: #E76C49;
  --infra-blue-dark: #491DE0;
  --infra-red-wine: #E01D52;
  --infra-orange: #E0AB1D;
  --infra-green: #1DE049;
  --infra-purple: #AB1DE0;
  --infra-white: #FFF;
  --infra-black: #000;
  --infra-gray: #D7D1C9;
  --infra-gray-light: #EDE8E2;
  --infra-form: linear-gradient(153deg, rgba(255, 247, 244, 1) 0%, rgb(245, 219, 212) 100%);
  --infra-nav-hover: rgb(225, 225, 225);
}