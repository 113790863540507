.h1 {
    font-family: "Archivo Black", sans-serif;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 5rem */
    word-wrap: break-word;
}

.h2 {
    font-family: "Archivo Black", sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 3.75rem */
    word-wrap: break-word;
}

.p1 {
    font-family: Archivo, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 2.5rem */
}

.p2 {
    font-family: Archivo, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.25rem */
}

.p3 {
    font-family: Archivo, sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.0625rem */
}

.p4 {
    font-family: Archivo, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.p-bold1 {
    font-family: Archivo, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 2.8125rem */
}

.p-bold2 {
    font-family: Archivo, sans-serif;
    font-size:  1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 2.5rem */
}

.p-bold3 {
    font-family: Archivo, sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 2.25rem */
}

.b1 {
    font-family: Archivo, sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.b2 {
    font-family: Archivo, sans-serif;
    font-size: 1.0rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.b-underline {
    font-family: Archivo, sans-serif;
    font-size: 1.0rem;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 2.25rem */
    text-decoration-line: underline;
}

.text-center {
    text-align: center;
}

.text-black {
    color: var(--InfraBlack, #000);
}

.text-white {
    color: var(--InfraWhite, #FFFBF7);
}

.half-width {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.no_bottom {
    padding-bottom: 0;
    margin-bottom: 0;
}

.no-mobile {
    display: inline;
}

.only-mobile {
    display: none;
}

@media only screen and (max-width: 1000px)  {
    .h1 {
        font-size: 2.8rem;
    }
    
    .h2 {
        font-size: 2.0rem;
    }
    
    .p1 {
        font-size: 1.25rem;
    }
    
    .p2 {
        font-size: 1.15rem;
    }
    
    .p3 {
        font-size: 1.0rem;
    }
    
    .p4 {
        font-size: 0.9rem;
    }
    
    .p-bold1 {
        font-size: 1.25rem;
    }
    
    .p-bold2 {
        font-size:  1.15rem;
    }
    
    .p-bold3 {
        font-size: 1.0rem;
    }
    
    .b1 {
        font-size: 1.1rem;
    }
    
    .b2 {
        font-size: 0.9rem;
    }
    
    .b-underline {
        font-size: 0.9rem;
    }

    .half-width {
        width: 75%;
    }

}

@media only screen and (max-width: 800px)  {
    .h1 {
        font-size: 1.9rem;
    }
    
    .h2 {
        font-size: 1.4rem;
    }
    
    .p1 {
        font-size: 1.25rem;
    }
    
    .p2 {
        font-size: 1.15rem;
    }
    
    .p3 {
        font-size: 1.0rem;
    }
    
    .p4 {
        font-size: 0.9rem;
    }
    
    .p-bold1 {
        font-size: 1.25rem;
    }
    
    .p-bold2 {
        font-size:  1.15rem;
    }
    
    .p-bold3 {
        font-size: 1.0rem;
    }
    
    .b1 {
        font-size: 1.1rem;
        line-height: 125%;
    }
    
    .b2 {
        font-size: 0.9rem;
    }
    
    .b-underline {
        font-size: 0.9rem;
    } 

    .half-width {
        width: 100%;
    }

    .no-mobile {
        display: none;
    }
    
    .only-mobile {
        display: inline;
    }
}