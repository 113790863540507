.products__landing-products {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
    padding: 0;
}

.products__landing-products_product {
    margin-bottom: 0.5rem;
}

.products__landing-products_product img {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    background: gainsboro;
    height: 16rem;
    object-fit: cover;
    margin: auto;
}

.products__landing-products_product p {
    text-align: center;
    margin-top: 1rem;
}

@media only screen and (max-width: 800px) {
    .products__landing-products_product img {
        height: 12rem;
        width: 75%;
    }
}